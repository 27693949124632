.ico {
  display: inline-flex;
  border-radius: 50px;
  position: relative;
  width: 6rem;
  height: 6rem;
  display: flex;
  justify-content: center;
  align-items: center;
  i {
    color: $color-primary;
    font-size: 2rem;
    line-height: 1;
    @media screen and (max-width: 767px) {
      font-size: $header-4;
    }
  }
  @media screen and (max-width: 767px) {
    width: 4rem;
    height: 4rem;
  }

  &::before {
    position: absolute;
    display: block;
    content: "";
    border-radius: 50px;
    top: 8px;
    bottom: 8px;
    left: 8px;
    right: 8px;
    border: 1px dashed $color-primary;
  }
  &--min {
    width: 4rem;
    height: 4rem;
    &::before {
      top: 5px !important;
      bottom: 5px !important;
      left: 5px !important;
      right: 5px !important;
    }
    img {
      max-height: 2rem;
    }
  }

  &__content {
    svg {
      fill: $color-primary;
    }
  }
}

.ico--cocinas {
  background-color: #b2ded4;
}

.ico--lg {
  // .bi {
  //   color: $color-primary;
  //   font-size: 2.5rem;
  //   line-height: 1;
  // }
  svg {
    width: 48px;
    height: 48px;
  }
}

.cards-container {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  &__item {
    margin: 8px;
  }
  @media screen and (max-width: 767px) {
    display: grid;
    grid-template-columns: 1fr 1fr;
  }
}

.mini-card {
  background-color: $color-background-light;
  display: flex;

  gap: 1rem;
  column-gap: 1rem;
  padding: 0.5rem 1rem;
  border-radius: $border-radius;
  width: auto;
  max-width: 280px;
  &__picture {
  }
  &__content {
    display: flex;
    align-items: center;
    line-height: 1.2;
  }
}
