.header-all {
  font-weight: $font-weight-bold;
  margin-bottom: 1.5rem;
  margin-top: 0;
  line-height: 1.4;
}

.header-1 {
  font-size: $header-1;
  @extend .header-all;
  @media screen and (max-width: 810px) {
    font-size: 2.1875rem;
  }
}
.header-2 {
  font-size: $header-2;
  @extend .header-all;
  @media screen and (max-width: 810px) {
    font-size: 1.875rem;
  }
}
.header-3 {
  font-size: $header-3;
  @extend .header-all;
  @media screen and (max-width: 810px) {
    font-size: 1.25rem;
  }
}
.header-4 {
  font-size: $header-4;
  @extend .header-all;
}
.header-5 {
  font-size: $header-5;
  @extend .header-all;
}
.header-6 {
  font-size: $header-6;
  @extend .header-all;
}

.h2-header {
  font-size: 3.25rem;
}
.h3-header {
  font-size: 2.1rem;
  @media screen and (max-width: 767px) {
    font-size: $header-3;
  }
}
.h3-header--verde {
  color: $color-primary;
  font-weight: bold;
}

.titulos {
  margin-bottom: $space-3;
  @media screen and (max-width: 810px) {
    margin-bottom: 2rem;
  }
  &__pretitulo {
    text-transform: uppercase;
    color: $color-primary;
    font-weight: $font-weight-medium;
    letter-spacing: 1px;
  }

  &__titulo {
    font-size: 3.25rem;
    color: $color-dark;
    @media screen and (max-width: 767px) {
      font-size: $header-2;
    }
    // @media screen and (max-width: 810px) {
    //   font-size: $header-2;
    // }
    // @media screen and (max-width: 575px) {
    //   font-size: $header-3;
    // }
  }

  &__subtitulo {
    color: $color-primary-light;
    @media screen and (max-width: 767px) {
      font-size: $header-4;
    }
  }

  &--center {
    text-align: center;
  }
  &--50 {
    @media screen and (min-width: 992px) {
      width: 50%;
      margin-left: auto;
      margin-right: auto;
    }
  }
  &--light {
    .titulos__pretitulo,
    h2,
    h3 {
      color: $color-white;
    }
  }
  &--mb-0 {
    margin-bottom: 0;
  }
  &--mb-01 {
    margin-bottom: $space-01;
  }
  &--mb-min {
    margin-bottom: 2rem;
  }
}

////////////////////////////////////////////////////////////////////////////////////////////////////////////
////////////////////////////////////////////////////////////////////////////////////////////////////////////
/////////////////////////////////////////////////////////////////////////////////////////////////////

// h1,
// h2,
// h3,
// h4,
// h5,
// h6 {
//   font-weight: 700;
// }

// .titulo {
//   margin-bottom: 4rem;
//   &--center {
//     text-align: center;
//   }
//   &--mb-min {
//     margin-bottom: 2rem;
//   }
//   &--blanco {
//     .titulo__pre,
//     h2,
//     h3 {
//       color: $color-background;
//     }
//   }

//   &__pre {
//     text-transform: uppercase;
//     color: $color-principal;
//     font-weight: 500;
//     letter-spacing: 1px;
//   }

//   &__titulo {
//     font-size: 3.25rem;
//     color: $color-dark;
//   }

//   &__sub {
//     color: $color-principal-light;
//   }
// }

// .h2-header {
//   font-size: 3.25rem;
// }
// .h3-header {
//   font-size: 2.1rem;
// }
