.cta-intro {
  text-align: center;
  margin-bottom: 2rem;
  @media screen and (max-width: 767px) {
    margin-bottom: 1rem;
  }
  h3 {
    font-size: 1.5rem;
    color: $color-primary-light;
    @media screen and (max-width: 767px) {
      font-size: 1.3rem;
    }
    span {
      display: block;
      color: $color-grey-3;
    }
  }
}

.cta {
  margin-top: $space-3;
  background: $color-primary-gradient;
  border-radius: $border-radius-max;
  padding: 6rem 0;
  @media screen and (max-width: 767px) {
    padding: 2rem 1rem;
    margin-top: $space-2;
  }
  h2 {
    @media screen and (max-width: 767px) {
      font-size: 2rem;
    }
  }
  .titulo {
    width: 70%;
    margin-left: auto;
    margin-right: auto;
  }
  &__actions {
    text-align: center;
    .boton {
      margin: 0 0.5rem;
      &:first-child() {
        margin-bottom: 0.5rem;
      }
    }
  }
}
