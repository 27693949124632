.cli-bar-container {
  justify-content: center !important;
  text-align: center;
}

.cli-bar-message {
  width: auto !important;
}

.cli-accept-button {
  background-color: $color-primary !important;
  color: $color-white !important;
  font-weight: $font-weight-bold !important;
  border-radius: 2px;
  &:hover {
    background-color: $color-primary-dark !important;
    color: $color-white !important;
  }
}
