.header {
  color: $color-text-body;
  background-color: $color-white;
  transition: background-color 0.5s linear, top 0.5s ease-in-out;
  position: sticky;
  top: 0;
  z-index: 30;
  padding: 1.5rem 0;
  @media screen and (max-width: 767px) {
    padding: 0.5rem 0;
  }
  &--sticky {
    box-shadow: none;
    transition: $trans-3;
    z-index: 30;
    padding: 0.5rem 0;
    box-shadow: $box-shadow;
  }

  &__container {
  }

  &__content {
    display: flex;
    justify-content: space-between;
    align-items: center;
    @media screen and (max-width: 767px) {
    }
  }

  &__logo {
    display: inline-block;
    height: 32px;
    width: auto;
    transition: $trans-3;
    margin-right: 2rem;
    @media screen and (max-width: 575px) {
      width: auto;
      height: 28px;
      transition: $trans;
    }
  }

  &__left {
    align-self: center;
    @media screen and (max-width: 991px) {
      display: none;
    }
    @media screen and (max-width: 767px) {
      display: none;
    }
  }

  &__right {
    align-self: center;
    display: inline-block;
    margin-left: auto;

    @media screen and (max-width: 991px) {
      margin-left: 0;
      margin-right: 2rem;
      display: none;
    }
    @media screen and (max-width: 767px) {
      margin-right: 1.5rem;
    }
  }

  &__burger {
    display: flex;
    align-items: center;
    a {
      color: $color-text-body;
      i {
        font-size: 2.5rem;
        transition: $trans;
      }
      &:hover {
        color: $color-grey-4;
      }
    }
    @media screen and (min-width: 992px) {
      display: none;
    }
    @media screen and (max-width: 991px) {
      display: inline-block;
    }
    @media screen and (max-width: 575px) {
      font-size: 1.5rem;
    }
  }
}
