.integracion {
  padding: $space-4 0;
  @media screen and (max-width: 767px) {
    padding: $space-2 0 $space-2 0;
  }
  &__content {
  }

  &__grid {
    display: grid;
    grid-template-columns: repeat(3, minmax(0, 1fr));
    gap: $space-3;
    @media screen and (max-width: 767px) {
      display: flex;
      flex-direction: column;
      gap: $space-1;
    }
  }

  &__item {
    padding: $space-2;
    background-color: $color-background-light;
    border-radius: $border-radius;
    box-shadow: $box-shadow;
    display: flex;
    flex-direction: column;
    align-items: center;
    text-align: center;
    @media screen and (max-width: 767px) {
      padding: $space-02;
    }
  }

  &__picture {
    display: flex;
    justify-content: center;
    margin-bottom: $space-1;
    @media screen and (max-width: 767px) {
      margin-bottom: 0.5rem;
    }
    img,
    svg {
      width: 70%;
      height: auto;
      max-height: 100px;
    }
  }

  &__funcionalidad {
  }

  &__software {
  }
}
