.wpcf7 {
  .form-group {
    margin-bottom: 1rem;
  }

  .form-control {
    width: 100%;
    padding: 0.675rem 0.75rem;
    font-size: 0.9rem;
    border-radius: 0;
    background-color: $color-white;
    color: $color-text-body;
  }

  .form-select {
    color: rgba($color: #000000, $alpha: 0.5) !important;
    font-size: 14.4px;
    option:nth-child(1) {
      opacity: 0.5 !important; /* Firefox */
    }
  }

  .form-control::placeholder {
    /* Chrome, Firefox, Opera, Safari 10.1+ */
    color: rgba($color: #000000, $alpha: 0.5);
    opacity: 1; /* Firefox */
  }

  // .form-control:-ms-input-placeholder {
  //   /* Internet Explorer 10-11 */
  //   color: rgba($color: #000000, $alpha: 0.5);
  // }

  // .form-control::-ms-input-placeholder {
  //   /* Microsoft Edge */
  //   color: rgba($color: #000000, $alpha: 0.5);
  // }
  // .wpcf7-submit {
  //   display: inline-block;
  //   border: 1px solid;
  //   border-radius: 0px;
  //   padding: 0.6rem 2.1875rem;
  //   text-decoration: none;
  //   transition: $trans;
  //   background-color: $color-secondary-dark;
  //   color: $color-white;
  //   border: 1px solid $color-secondary-dark;
  //   &:hover {
  //     background-color: lighten($color: $color-secondary-dark, $amount: 10);
  //     color: $color-white;
  //   }
  // }

  .wpcf7-not-valid-tip {
    font-size: 12px;
    color: red;
  }
}

.wpcf7-form__margen {
  margin-bottom: 1rem;
}

.wpcf7-list-item {
  margin-left: 0 !important;
}

.wpcf7-acceptance {
  font-size: 0.8rem;
  color: $color-grey-1;
  a {
    color: $color-grey-1;
    &:hover {
      color: $color-primary-dark;
    }
  }

  .wpcf7-list-item {
    // margin-left: 0;
  }
}

.wpcf7-response-output {
  margin: 0 0.5em 1em;
  padding: 0.5em 1em;
  border-radius: 8px;
}

.wpcf7-mail-sent-ok {
  border: 2px solid #398f14;
  background-color: #398f14;
  color: $color-white;
}

.wpcf7-mail-sent-ng {
  border: 2px solid #ff0000;
  background-color: #ff0000;
  color: $color-white;
}

.wpcf7-spam-blocked {
  border: 2px solid #ffa500;
  background-color: #ffa500;
  color: $color-white;
}

.wpcf7-acceptance-missig {
  border: 2px solid #ffa500 !important;
  background-color: #ffa500 !important;
  color: $color-white;
}

body:not(.page-template-page-contacto) .grecaptcha-badge {
  visibility: hidden;
}
