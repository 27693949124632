.page,
.post {
  &__entry {
    line-height: 1.7;
    p,
    ul,
    ol,
    blockquote {
      // a {
      //   color: $color-accent !important;
      //   &:hover {
      //     color: $color-accent-dark !important;
      //   }
      // }
    }
    h1,
    h2,
    h3,
    h4,
    h5,
    h6 {
      line-height: 1.6;
      margin-bottom: 1.5rem;
    }
    p,
    ol,
    ul {
      font-size: 1.1rem;
      @media print {
        font-size: 10pt;
      }
    }
    p,
    ol,
    ul,
    blockquote {
      margin-bottom: 1.5rem;
    }

    ul {
      li {
        margin-bottom: 10px;
      }
    }

    h2 {
      font-size: 2.2rem;
      color: $color-primary-dark !important;
      font-weight: 700;
      @media screen and (max-width: 575px) {
        font-size: 2rem;
        line-height: 1.3;
      }
      & strong {
        font-weight: 400;
      }
    }
    h3 {
      font-size: 1.5rem;
      color: $color-primary-dark;
      @media screen and (max-width: 575px) {
        font-size: 1.4rem;
        line-height: 1.3;
      }
    }
    strong {
      color: $color-grey-2;
    }
    .wp-block-pullquote {
      padding: 2rem 0 !important;
      text-align: left !important;
      margin-bottom: 0 !important;
    }
    .wp-block-image {
      margin: 2rem 0;

      @media print {
        width: 80%;
        margin-left: auto;
        margin-right: auto;
      }

      img {
        width: 100%;
        height: auto;
        border-radius: $border-radius !important;
        box-shadow: $box-shadow;
      }
      figcaption {
        text-align: center;
        font-size: $font-size-smaller;
      }
    }
    .wp-block-gallery {
      margin: 1.8rem 0;
      .blocks-gallery-item {
        margin-bottom: 0;
      }
      img {
        width: 100%;
        height: auto;
      }
      figcaption {
        text-align: center;
      }
    }
    .block-wikiloc {
      iframe {
        width: 100%;
        margin-top: 2rem !important;
        margin-bottom: 2rem;
        box-shadow: $box-shadow;
        border-radius: 0.5rem;
      }
      div {
        display: none;
      }
    }
  }
}

.page-id-1033 .page__entry {
  h2 {
    color: $color-secondary-dark;
  }
  h3 {
    color: $color-secondary-dark;
  }
}

////////////////////////////////////////////////////////////////////////////////////////////////////////////
////////////////////////////////////////////////////////////////////////////////////////////////////////////
/////////////////////////////////////////////////////////////////////////////////////////////////////
///

// .page {
//   &__entry {
//     p,
//     ul,
//     ol {
//       margin-bottom: 1.8rem;
//       font-size: 1rem;
//       line-height: 1.65;
//       @media screen and (max-width: 575px) {
//         margin-bottom: 1.2rem;
//         font-size: 1rem;
//         line-height: 1.65;
//       }
//     }
//     p {
//     }
//     h2,
//     h3,
//     h4,
//     h5,
//     h6 {
//       font-family: $typo-principal;
//       font-weight: bold;
//       margin-bottom: 1.8rem;
//       @media screen and (max-width: 575px) {
//         margin-bottom: 1rem;
//       }
//     }
//     .wp-block-image {
//       margin-top: 1.8rem !important;
//       margin-bottom: 1.8rem !important;
//     }
//     h2 {
//       color: $color-principal-dark;
//     }
//     h3 {
//       font-size: 1.4rem;
//       color: $color-principal-dark;
//     }
//   }
// }

// .page-id-1033 .page__entry {
//   h2 {
//     color: $color-secundario-dark;
//   }
//   h3 {
//     color: $color-secundario-dark;
//   }
// }
