.widget-footer {
  &__title {
    font-size: $font-size;
    color: $color-primary;
    text-transform: uppercase;
    margin-bottom: 1rem;
  }
}

.widget {
  .menu {
    list-style: none;
    padding-left: 0;
    .menu-item {
      margin-bottom: 4px;
      a {
      }
    }
  }
}
