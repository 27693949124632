.hero {
  margin-left: auto;
  margin-right: auto;
  max-width: 1800px;
  &__wrapper {
    background: $color-grey-6;
    position: relative;
    overflow: hidden;
    @media screen and (max-width: 1280px) {
      margin: $space-2;
    }
    @media screen and (max-width: 767px) {
      padding: $space-1 0;
      margin: $space-0;
      border-radius: inherit;
    }
  }
  &__container {
  }

  &__grid {
    display: flex;
    gap: $space-3;
    align-items: center;
    position: relative;
    z-index: 1;
    padding: $space-6;
    @media screen and (max-width: 991px) {
      flex-direction: column;
    }
    @media screen and (max-width: 767px) {
      gap: $space-1;
      text-align: center;
      padding: $space-1;
    }
  }

  &__content {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
  }

  &__titulo {
    font-size: $display-2;
    font-weight: $font-weight-bold;
    color: $color-white;
    text-align: center;
    @media screen and (max-width: 1399px) {
      font-size: $display-2;
    }
    @media screen and (max-width: 1280px) {
      font-size: $header-1;
    }
    @media screen and (max-width: 767px) {
      font-size: $header-2;
    }
  }

  &__subtitulo {
    font-size: $header-4;
    font-weight: $font-weight-medium;
    color: $color-white;
    @media screen and (max-width: 767px) {
      font-size: $header-5;
    }
    span {
      color: $color-secondary-light;
      @media screen and (max-width: 767px) {
        display: block;
      }
    }
  }
  &__actions {
    margin-top: $space-1;
    display: flex;
    gap: $space-1;
    flex-wrap: wrap;
    @media screen and (max-width: 767px) {
      gap: $font-size-smaller;
      justify-content: center;
    }
  }
  &__overlay {
    background-color: rgba(0, 58, 84, 0.6);
    height: 100%;
    width: 100%;
    content: "";
    display: block;
    position: absolute;
    top: 0;
    bottom: 0;
  }
  &__video {
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    object-fit: cover;
    z-index: 0;
    transition: $trans;
    filter: blur(4px);
    transform: scale(1);
    @media screen and (min-width: 1419px) {
      // max-width: 1419px;
      margin-left: auto;
      margin-right: auto;
      transition: $trans;
    }
  }
}

.page-title {
  position: relative;
  padding-top: $space-02;
  padding-bottom: $space-2;
  background: $color-primary-gradient;
  color: $color-white;
  @media screen and (max-width: 575px) {
    padding-top: $space-02;
    padding-bottom: $space-02;
  }

  &__content {
    position: relative;
  }

  &__breadcrumbs {
    color: $color-white;
    font-size: 0.8rem;
    // text-transform: uppercase;
    margin-bottom: 3rem;
    @media screen and (max-width: 767px) {
      margin-bottom: 1rem;
    }
    a {
      color: $color-white;
      font-weight: bold;
      text-decoration: none;
    }
  }

  &__header {
    font-weight: $font-weight-bold;
    font-size: 1.75rem;
    margin-top: 0;
    color: $color-grey-04;
    @media screen and (max-width: 767px) {
      font-size: 1.2rem;
    }
  }
  &__subtitulo {
    font-size: 4rem;
    font-weight: $font-weight-bold;
    @media screen and (max-width: 767px) {
      font-size: 2rem;
    }
  }
}
