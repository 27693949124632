$color-primary: #00795e;
$color-primary-light: #6cac48;
$color-primary-dark: #003a4a;

$color-primary-gradient: linear-gradient(
  120deg,
  rgba(0, 58, 74, 1) 0%,
  rgba(108, 172, 72, 1) 100%
);

$color-secondary: #a50555;
$color-secondary-light: #ff7aaf;
$color-secondary-dark: #a50555;

$color-accent: #dee07d;
$color-accent-light: #d797ff;
$color-accent-dark: #4f0080;

$color-grey-1: #262626;
$color-grey-2: #424142;
$color-grey-3: #505858;
$color-grey-4: #b6b6b6;
$color-grey-04: rgba(255, 255, 255, 0.67);

$color-grey-5: #d7d7d7;
$color-grey-6: #f5f5f5;

$color-white: #fff;
$color-dark: #003a4a;

$color-background-light: rgb(244, 244, 244);
$color-background: $color-white;
$color-background-06: rgba(255, 255, 255, 0.6);
$color-background: $color-white;

$color-fondo-hero: #030303;

$color-whatsapp-dark: #128c7e;
$color-whatsapp-light: #25d366;

$color-text-body: #3c3c3b;
$color-text-muted: $color-grey-4;

$font-size-smaller: 0.75rem;
$font-size-small: 0.9rem;
$font-size: 1rem;
$font-size-big: 1.125rem;
$font-size-bigger: 1.25rem;

$font-weight-light: 200;
$font-weight-normal: 400;
$font-weight-medium: 500;
$font-weight-bold: 700;

$link: $color-accent;

$box-shadow-small: 0px 0px 15px rgba(0, 0, 0, 0.1);
$box-shadow: 0px 8px 16px rgba(30, 0, 40, 0.08) !important;
$box-shadow-large: 0px 16px 48px rgba(30, 0, 40, 0.1);

$box-shadow-02: 0 0 30px 0 rgba(0, 0, 0, 0.07);
$box-shadow: 0 0 30px 0 rgba(0, 0, 0, 0.2);
$box-shadow-hover: 0 0 20px 0 rgba(0, 0, 0, 0.05);

$border-radius-min: 0.3rem;
$border-radius: 0.625rem;
$border-radius-max: $font-size * 2;
$border-radius-cta: 2.5rem;

$trans: 0.2s all ease-in-out;
$trans-3: 0.3s all ease-in-out;
$trans-5: 0.5s all ease-in-out;
$trans-4: 1s all ease-in-out;

$header-1: 3rem;
$header-1-res: 2.5rem;
$header-2: 2.1875rem;
$header-2-res: 1.875rem;
$header-3: 1.5rem;
$header-3-res: 1.25rem;
$header-4: 1.25rem;
$header-5: 1rem;
$header-6: 0.87rem;

$display-extra-large: 8.375rem;
$display-1: 6rem;
$display-2: 4.35rem;
$display-3: 3.45rem;
$display-4: 2.18rem;

$space-0: 0px;
$space-05: 0.5rem;
$space-01: 1rem;
$space-1: 1.5rem; //$sp-05
$space-02: 2rem;
$space-2: 3rem; //$sp-1
$space-3: 4rem;
$space-4: 5rem;
$space-5: 7rem; //$sp-2
$space-6: 9.375rem;
$space-7: 12rem;

$typo-primary: "Roboto", sans-serif;
// $typo-secondary: "Source Serif Pro", serif;
// $typo-alternative: "Dancing Script", cursive;

////////////////////////////////////////////////////////////////////////////////////////////////////////////
////////////////////////////////////////////////////////////////////////////////////////////////////////////
/////////////////////////////////////////////////////////////////////////////////////////////////////

// $color-principal-light: #6cac48;
// $color-principal: #00795e;
// $color-principal-dark: #003a4a;

// $color-secundario: #a50555;
// $color-secundario-dark: #a50555;

// $color-deg-principal: linear-gradient(
//   120deg,
//   rgba(0, 58, 74, 1) 0%,
//   rgba(108, 172, 72, 1) 100%
// );

// $color-alternativo: #dee07d;

// $color-gris-01: #e5e5e5;
// $color-gris-02: #bababa;
// $color-gris-03: #505858;
// $color-gris-04: #191c1c;

// $color-dark: #003a4a;

// $color-background-light: rgb(244, 244, 244);
// $color-background: rgba(255, 255, 255, 1);
// $color-background-06: rgba(255, 255, 255, 0.6);

// $color-texto: #3c3c3b;

// $color-whatsapp: #128c7e;

// $font-size: 1rem;
// $font-size-small: 0.8rem;
// $font-size-smaller: 0.7rem;

// $font-weight: 400;

// $box-shadow-02: 0 0 30px 0 rgba(0, 0, 0, 0.07);
// $box-shadow: 0 0 30px 0 rgba(0, 0, 0, 0.2);
// $box-shadow-hover: 0 0 20px 0 rgba(0, 0, 0, 0.05);

// $border-radius: $font-size;
// $border-radius-max: $font-size * 2;

// $trans: 0.2s all ease-in-out;
// $trans-3: 0.3s all ease-in-out;
// $trans-5: 0.5s all ease-in-out;
// $trans-4: 1s all ease-in-out;

// $sp-05: 1.5rem;
// $sp-1: 3rem;
// $sp-2: 7rem;
// $sp-3: 12rem;

// $typo-principal: "Roboto", sans-serif;
// $typo-principal: "Roboto", sans-serif;
