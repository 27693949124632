.contacto {
  padding: $space-4 0;
  @media screen and (max-width: 991px) {
    padding: $space-2 0;
  }

  h2 {
    color: $color-dark;
    font-weight: $font-weight-bold;
  }

  &__content {
    //color: $color-white;
    display: grid;
    grid-template-columns: 1fr 2fr;
    @media screen and (max-width: 991px) {
      display: flex;
      flex-direction: column;
    }
  }

  &__col1 {
    @media screen and (max-width: 991px) {
      margin-bottom: $space-2;
    }
    @media screen and (max-width: 767px) {
      margin-bottom: $space-1;
    }
  }

  &__col2 {
    padding-left: 5rem;
    display: flex;
    flex-direction: column;
    //color: $color-white;

    @media screen and (max-width: 991px) {
      padding-left: 0;
    }
  }

  &__descripcion {
    margin-bottom: 2rem;
    line-height: 1.8;
  }

  &__telefonos {
    display: flex;
    flex-direction: column;
    margin-bottom: 2rem;
    margin-left: 0.725rem;
    font-size: $font-size;
    @media screen and (max-width: 991px) {
      margin-bottom: $space-01;
    }
    a {
      text-decoration: none;
    }
  }

  &__telefonos-item {
    display: flex;
    margin-bottom: 0.2rem;
    .bi {
      margin-right: 0.5rem;
    }
    &:nth-last-child(1) {
      //margin-top: 0.25rem;
      margin-bottom: 0;
    }
    &--whats {
      margin-bottom: 1rem;
    }
    &--mail {
      display: flex;
      flex-direction: column;
      margin-bottom: 0.5rem;
      &::nth-last-child(1) {
        margin-bottom: 0;
      }
    }
  }

  &__social {
    .zoom-social-icons-list {
      //margin-left: 0 !important;
      display: flex;
      justify-content: flex-start;
      .socicon {
        width: 3rem !important;
      }
    }
    .social-icon {
      font-size: 28px !important;
      color: $color-white !important;
      background-color: $color-secondary !important;
      transition: $trans !important;
      @media screen and (max-width: 767px) {
        font-size: 26px !important;
      }
      &:hover {
        color: $color-white !important;
        background-color: darken($color-secondary, 5%) !important;
        box-shadow: $box-shadow-small;
        transform: translateY(-2px);
        transition: $trans;
      }
    }
  }
}

.page-template-page-contacto .ubicacion {
  padding-top: $space-3;
  padding-bottom: 0;
}

.b-contacto {
  display: flex;
  flex-wrap: wrap;
  gap: 0.5rem;
}

.wpcf7 .form-group {
  @media screen and (max-width: 767px) {
    margin-bottom: 0rem;
  }
}
