.cursor-pointer {
  cursor: pointer;
}

// Utilitats de colors i backgrounds
// ------------------------------------------------
$colors: (
  "primary" $color-primary,
  "primary-light" $color-primary-light,
  "primary-dark" $color-primary-dark,
  "secondary" $color-secondary,
  "secondary-light" $color-secondary-light,
  "secondary-dark" $color-secondary-dark,
  "accent" $color-accent,
  "accent-light" $color-accent-light,
  "accent-dark" $color-accent-dark,
  "white" $color-white,
  "dark" $color-dark,
  "grey-1" $color-grey-1,
  "grey-2" $color-grey-2,
  "grey-3" $color-grey-3,
  "grey-4" $color-grey-4,
  "grey-5" $color-grey-5,
  "grey-6" $color-grey-6,
  "background-light" $color-background-light
);

@each $name, $color in $colors {
  .bg-#{$name} {
    background: $color !important;
  }
}

@each $name, $color in $colors {
  .color-#{$name} {
    color: $color !important;
  }
}

// Mides funcionals per a espais
// ------------------------------------------------
$sizes: (
  "top" "t" "0" $space-0,
  "top" "t" "1" $space-1,
  "top" "t" "2" $space-2,
  "top" "t" "3" $space-3,
  "top" "t" "4" $space-4,
  "top" "t" "5" $space-5,
  "bottom" "b" "0" $space-0,
  "bottom" "b" "1" $space-1,
  "bottom" "b" "2" $space-2,
  "bottom" "b" "3" $space-3,
  "bottom" "b" "4" $space-4,
  "bottom" "b" "5" $space-5
);

// loop top i bottom
@each $direction, $d, $name, $size in $sizes {
  .pad-#{$d}-#{$name} {
    padding-#{$direction}: $size !important;
    @media screen and (max-width: 575px) {
      @if ($space-1) or ($space-2) {
        padding-#{$direction}: $size / 1.5 !important;
      } @else {
        padding-#{$direction}: $size !important;
      }
    }
  }
  .mar-#{$d}-#{$name} {
    margin-#{$direction}: $size !important;
    @media screen and (max-width: 575px) {
      @if ($space-1) or ($space-2) {
        margin-#{$direction}: ($size / 1.5) !important;
      } @else if ($space-5) {
        margin-#{$direction}: $space-2 !important;
      } @else {
        margin-#{$direction}: $size !important;
      }
    }
  }
}

// loop top i bottom en y
@each $direction, $d, $name, $size in $sizes {
  .pad-#{$name} {
    padding: $size 0;
    @media screen and (max-width: 575px) {
      @if $space-1 {
        padding: $space-1 0;
      } @else if $space-2 {
        padding: $space-2 0;
      } @else if $space-5 {
        padding: $space-2 0;
      } @else {
        padding: $size 0;
      }
    }
  }
  .mar-#{$name} {
    margin: $size 0 !important;
    @media screen and (max-width: 575px) {
      @if $space-1 {
        margin: $size / 1.5 0 !important;
      } @else if $space-2 {
        margin: $size / 1.5 0 !important;
      } @else {
        margin: $size 0 !important;
      }
    }
  }
}

.img-rounded {
  border-radius: $border-radius;
  box-shadow: $box-shadow;
}

.lista-tags {
  display: flex;
  flex-wrap: wrap;
  margin-bottom: 0.5rem;
  &--va {
    @media screen and (max-width: 575px) {
      justify-content: center;
      .lista-tags__item {
        background-color: $color-secondary;
        color: $color-white;
        justify-content: center;
        a {
          color: $color-white;
        }
      }
    }
  }

  &__item {
    margin-right: $font-size-small;
    margin-bottom: 0.5rem;
    background-color: $color-white;
    padding: 2px 6px;
    border-radius: 3px;
    font-size: $font-size-small;
    a {
      color: $color-grey-2;
    }
    &:nth-last-child(1) {
      margin-right: 0;
    }
  }
}

.container--res {
  max-width: 1240px;
  @media screen and (max-width: 991px) {
    max-width: calc(100vw - 2rem);
  }
  @media screen and (max-width: 575px) {
    max-width: 100vw;
  }
}

// .pad-b-min {
//   padding-bottom: $sp-1;
// }
// .mar-b-min {
//   margin-bottom: $sp-1;
// }
// .pad-b {
//   padding-bottom: $sp-2;
// }
// .mar-b {
//   margin-bottom: $sp-2;
// }
// .pad-b-max {
//   padding-bottom: $sp-3;
// }
// .mar-b-max {
//   margin-bottom: $sp-3;
// }

// .pad-t-min {
//   padding-top: $sp-1;
// }
// .mar-t-min {
//   margin-top: $sp-1;
// }
// .pad-t {
//   padding-top: $sp-2;
// }
// .mar-t {
//   margin-top: $sp-2;
// }
// .pad-t-max {
//   padding-top: $sp-3;
// }
// .mar-t-max {
//   margin-top: $sp-3;
// }

// .pad-min {
//   padding: $sp-1 0;
// }
// .pad {
//   padding: $sp-2 0;
// }
// .pad-max {
//   padding: $sp-3 0;
// }

// .mar-min {
//   margin: $sp-1 0;
// }
// .mar {
//   margin: $sp-2 0;
// }
// .mar-max {
//   margin: $sp-3 0;
// }

// @media screen and (max-width: 575px) {
//   .pad-b-min {
//     padding-bottom: $sp-05;
//   }
//   .mar-b-min {
//     margin-bottom: $sp-05;
//   }
//   .pad-b {
//     padding-bottom: $sp-1;
//   }
//   .mar-b {
//     margin-bottom: $sp-1;
//   }
//   .pad-b-max {
//     padding-bottom: $sp-2;
//   }
//   .mar-b-max {
//     margin-bottom: $sp-2;
//   }

//   .pad-t-min {
//     padding-top: $sp-05;
//   }
//   .mar-t-min {
//     margin-top: $sp-05;
//   }
//   .pad-t {
//     padding-top: $sp-1;
//   }
//   .mar-t {
//     margin-top: $sp-1;
//   }
//   .pad-t-max {
//     padding-top: $sp-2;
//   }
//   .mar-t-max {
//     margin-top: $sp-2;
//   }

//   .pad-min {
//     padding: $sp-05 0;
//   }
//   .pad {
//     padding: $sp-1 0;
//   }
//   .pad-max {
//     padding: $sp-2 0;
//   }

//   .mar-min {
//     margin: $sp-05 0;
//   }
//   .mar {
//     margin: $sp-1 0;
//   }
//   .mar-max {
//     margin: $sp-2 0;
//   }
// }

// .color-primario {
//   background-color: $color-principal;
// }
// .bg-primario {
//   background-color: $color-principal;
// }
// .bg-background-light {
//   background-color: $color-background-light;
// }

// .font-smooth {
//   -webkit-font-smoothing: antialiased;
//   -moz-font-smoothing: antialiased;
//   -moz-osx-font-smoothing: grayscale;
//   -ms-font-smoothing: antialiased;
//   -o-font-smoothing: antialiased;
//   font-smooth: antialiased;
// }

// .desktop {
//   @media screen and (max-width: 991px) {
//     display: none;
//   }
// }
// .mvl {
//   @media screen and (min-width: 992px) {
//     display: none;
//   }
// }
