.footer {
  background-color: $color-background-light;
  padding: 4rem 0;
  @media screen and (max-width: 575px) {
    padding: 2rem 0;
  }

  &__container {
    margin-bottom: 6rem;
    display: grid;
    gap: 3rem;
    grid-template-columns: repeat(4, minmax(0, 1fr));
    @media screen and (max-width: 767px) {
      display: flex;
      flex-direction: column;
      align-items: center;
      text-align: center;
      gap: 0.5rem;
      margin-bottom: 1rem;
    }
  }
  &__item--logo {
    @media screen and (max-width: 767px) {
      margin-bottom: 2rem;
    }
  }
}

.copyright {
  @media screen and (max-width: 767px) {
    text-align: center;
  }
  &__copy {
    margin-bottom: 0.3rem;
  }
  &__dev {
    a {
      text-decoration: underline;
    }
  }
}
