@mixin button {
  padding: 0.9rem 1.2rem; // cambiar tamaño
  font-size: 1rem;
  border-radius: 50px; // cambiar radius
  font-weight: $font-weight-medium;
  line-height: 1;
  text-align: center;
  cursor: pointer;
  border: 0;
  transition: $trans;
  display: inline-flex;
  justify-content: center;
  align-items: center;
  text-decoration: none;
  border-width: 2px;
  border-style: solid;
  &--lg {
    padding: 1.125rem 1.9375rem;
    @media screen and (max-width: 767px) {
      padding: 1rem 1.475rem;
    }
  }
  &--sm {
    padding: 0.625rem 1.25em;
    font-size: $font-size-small;
  }
}

.boton {
  @include button;
}

$buttons: (
  // nom, color fondo, color texto, color texto-outline
  "primary" $color-primary $color-white $color-grey-2,
  "secondary" $color-secondary $color-white $color-grey-2,
  "accent" $color-accent $color-white $color-accent,
  "white" $color-white $color-grey-2 $color-grey-2,
  "dark" $color-grey-2 $color-white $color-grey-3,
  "whatsapp" $color-whatsapp-light $color-white $color-grey-2
);

@each $name, $c-background, $c-text, $c-text-outline in $buttons {
  .boton--#{$name} {
    background: $c-background;
    color: $c-text;
    border-color: $c-background;
    transition: $trans;
    &:hover {
      background: darken($c-background, 5%);
      border-color: darken($c-background, 5%);
      color: $c-text;
      box-shadow: $box-shadow-small;
      transform: translateY(-2px);
      transition: $trans;
    }
  }

  .boton--#{$name}-outline {
    // background: $c-background;
    color: $c-text-outline;
    border-color: $c-background;
    transition: $trans;
    &:hover {
      background: darken($c-background, 5%);
      border-color: darken($c-background, 5%);
      color: $c-text;
      box-shadow: $box-shadow-small;
      transform: translateY(-2px);
      transition: $trans;
    }
  }
}

////////////////////////////////////////////////////////////////////////////////////////////////////////////
////////////////////////////////////////////////////////////////////////////////////////////////////////////
/////////////////////////////////////////////////////////////////////////////////////////////////////

// .boton {
//   display: inline-block;
//   padding: 12px 30px;
//   font-weight: 500;
//   font-size: 0.95rem;
//   line-height: 20px;
//   color: #fff;
//   transition: $trans;
//   text-decoration: none;
//   border-radius: 50px;
//   border: 2px solid;
//   -webkit-font-smoothing: antialiased;
//   -moz-font-smoothing: antialiased;
//   -moz-osx-font-smoothing: grayscale;
//   -ms-font-smoothing: antialiased;
//   -o-font-smoothing: antialiased;
//   font-smooth: antialiased;
//   @media screen and (max-width: 991px) {
//     padding: 10px 22px;
//   }

//   &:hover {
//     text-decoration: none;
//     transition: $trans;
//   }

//   &:disabled {
//     background-color: lighten($color: $color-principal-dark, $amount: 10);

//     &:hover {
//       background-color: lighten($color: $color-principal-dark, $amount: 10);
//     }
//   }
// }

// .boton--sm {
//   padding: 9px 20px;
//   font-size: 0.9rem;
// }

// .boton--principal {
//   background-color: $color-principal;
//   color: $color-background;
//   border-color: $color-principal;
//   &:hover {
//     background-color: lighten($color: $color-principal, $amount: 10);
//     border-color: lighten($color: $color-principal, $amount: 10);
//     color: $color-background;
//   }
// }

// .boton--secundario {
//   background-color: $color-secundario;
//   color: $color-background;
//   border-color: $color-secundario;
//   &:hover {
//     background-color: lighten($color: $color-secundario, $amount: 10);
//     border-color: lighten($color: $color-secundario, $amount: 10);
//     color: $color-background;
//   }
// }

// .boton--alternativo {
//   background-color: $color-secundario;
//   color: $color-background;
//   border-color: $color-secundario;
//   &:hover {
//     background-color: lighten($color: $color-secundario, $amount: 10);
//     border-color: lighten($color: $color-secundario, $amount: 10);
//     color: $color-background;
//   }
// }

// .boton--blanco {
//   background-color: $color-background;
//   color: $color-texto;
//   border-color: $color-background;
//   &:hover {
//     background-color: darken($color: $color-background, $amount: 10);
//     color: $color-texto;
//     border-color: darken($color: $color-background, $amount: 10);
//   }
// }
