body {
  background-color: $color-background;
  font-size: $font-size;
  color: $color-text-body;
  font-weight: $font-weight-normal;
  font-family: $typo-primary;
  -webkit-font-smoothing: antialiased;
  -moz-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  -ms-font-smoothing: antialiased;
  -o-font-smoothing: antialiased;
  font-smooth: antialiased;
  p {
    font-size: $font-size;
  }

  @media screen and (max-width: 575px) {
    font-size: $font-size * 0.9;
  }
}

a {
  color: $color-text-body;
  text-decoration: none;
  &:hover {
    color: $color-primary-dark;
    text-decoration: none;
  }
}

.menu-visible {
  overflow-y: hidden;
}
