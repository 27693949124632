.menu-principal {
  &__nav {
    display: flex;
    align-items: center;
    margin: auto 0;
    list-style: none;
    padding-left: 0;
    & > .menu-item {
      position: relative;
      display: inline-flex;
      & > a {
        padding: 9px 20px;
        display: block;
      }
    }
    .menu-item.menu-item-has-children {
      margin-right: 10px;
      position: relative;
      &::after {
        position: absolute;
        top: 1rem;
        right: 3px;
        content: "";
        background: url("../assets/ico/ico-menu-arrow.svg") no-repeat center
          center;
        background-size: 10px 10px;
        display: inline-block;
        width: 10px;
        height: 10px;
      }
      &:hover {
        .sub-menu {
        }
      }
    }
    .sub-menu {
      display: none;
      top: 2.5rem;
      margin-left: -1.5rem;
      background-color: $color-white;
      padding: 1.5rem 3rem 1rem;
      border-radius: $border-radius;
      box-shadow: $box-shadow;
      margin-bottom: 0;
      list-style: none;
      position: absolute;
      min-width: 400px;
      & > .menu-item {
        padding-bottom: 1rem;
        margin-bottom: 1rem;
        border-bottom: 1px dashed rgba(0, 0, 0, 0.2);
        &:last-child {
          border-bottom: none;
          margin-bottom: 0rem;
        }
        a {
          font-weight: bold;
          .sub {
            display: block;
            font-weight: 400;
            font-size: $font-size-small;
            color: $color-grey-3;
          }
        }
      }
      &__grid {
        display: grid;
        grid-template-columns: repeat(2, minmax(0, 1fr));
        gap: 0 $space-3;
        min-width: 768px;
        //width: max-content;
        & > .menu-item {
          border-bottom: none;
          margin-bottom: 0;
          //width: max-content;
          a {
            .sub {
              font-size: $font-size-small;
            }
          }
        }
      }
    }
  }
}
.menu-secundario {
  &__nav {
    display: flex;
    align-items: center;
    margin: auto 0;
    list-style: none;

    .menu-item {
      margin-right: 2rem;
    }
  }
}

.menu-secundario__nav .menu-item:nth-last-child(1) {
  margin-right: 0;
  a {
    @extend .boton;
    @extend .boton--secondary;
    @extend .boton--sm;
  }
}

.off-nav {
  &__nav {
    list-style: none;
    padding-left: 0;
    margin-bottom: 0;
    & > li.menu-item {
      margin-bottom: calc($font-size-smaller / 2);
      a {
        color: $color-grey-2;
        text-decoration: none;
        font-weight: $font-weight-medium;
        font-size: $header-3;
      }
      &.current-menu-item {
        a {
          color: $color-secondary-dark;
        }
      }
    }
  }
}
