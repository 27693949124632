.grid-features {
  display: flex;
  justify-content: space-around;
  margin-bottom: 3rem;
  @media screen and (max-width: 767px) {
    display: flex;
    flex-direction: column;
    margin-bottom: $space-1;
  }
  &__item {
    text-align: center;
    margin: 0 2rem;
    margin-bottom: 1.5rem;
    @media screen and (max-width: 767px) {
      display: flex;
      align-items: center;
      gap: $space-1;
      margin: 1rem 2rem;
    }
  }

  &__ico {
    display: flex;
    justify-content: center;
    svg {
      fill: $color-primary;
    }
  }

  &__desc {
    h2 {
      font-size: 1.2rem;
      color: $color-primary-dark;
    }
    @media screen and (max-width: 767px) {
      text-align: left;
    }
  }
}

.padding-section {
  padding: $space-5 0;
  @media screen and (max-width: 767px) {
    padding: $space-2 0;
  }
}
