.solucion {
  padding: $space-5 0 $space-3 0;
  @media screen and (max-width: 767px) {
    padding: $space-2 0 $space-1 0;
  }
  &__content {
  }
}

.mas-soluciones {
  padding: $space-3 0 $space-5 0;
  @media screen and (max-width: 767px) {
    padding: $space-1 0 $space-2 0;
  }
  &__content {
  }

  &__grid {
    display: grid;
    grid-template-columns: repeat(6, minmax(0, 1fr));
    gap: $space-3;
    @media screen and (max-width: 767px) {
      display: flex;
      flex-direction: column;
      gap: $space-1;
    }
  }

  &__grid--1 {
    .mas-soluciones__item:nth-last-child(1) {
      grid-column: 3 / span 2;
    }
  }

  &__grid--2 {
    .mas-soluciones__item:nth-last-child(2) {
      grid-column: 2 / span 2;
    }
    .mas-soluciones__item:nth-last-child(1) {
      grid-column: 4 / span 2;
    }
  }

  &__item {
    grid-column: span 2;
    padding: $space-2;
    background-color: $color-background-light;
    border-radius: $border-radius;
    box-shadow: $box-shadow;
    display: flex;
    flex-direction: column;
    align-items: center;
    text-align: center;
    @media screen and (max-width: 767px) {
      padding: $space-1;
    }
  }

  &__icon {
    width: 56px;
    height: 56px;
    border-radius: $border-radius;
    background-color: $color-primary;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-bottom: $space-01;
    // i {
    //   color: $color-white;
    //   font-size: 2rem;
    // }
    svg {
      fill: $color-white;
    }
  }

  &__title {
  }

  &__subtitle {
  }
}
