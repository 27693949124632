.s-feat {
  &__item {
    display: flex;
    margin-bottom: $space-5;
    @media screen and (max-width: 991px) {
      margin-bottom: $space-3;
    }
    @media screen and (max-width: 810px) {
      margin-bottom: $space-2;
    }
    &:nth-last-child(1) {
      margin-bottom: 0;
    }
  }

  &__card {
    display: grid;
    grid-template-columns: repeat(2, minmax(0, 1fr));
    // @media screen and (min-width: 1400px) {
    //   grid-template-columns: 1fr 1fr;
    // }
    @media screen and (max-width: 991px) {
    }
    @media screen and (max-width: 810px) {
      grid-template-columns: 1fr;
    }

    &--inverted {
      .s-feat__card-picture {
        order: 2;
        @media screen and (max-width: 810px) {
          order: 1;
        }
      }
      .s-feat__card-content {
        order: 1;
        padding: 0 4rem 0 0;
        @media screen and (max-width: 991px) {
          padding: 0 2rem 0 0;
        }
        @media screen and (max-width: 810px) {
          padding: 1.5rem 0;
          order: 2;
          padding: 0.2rem 0;
          @media screen and (max-width: 810px) {
          }
        }
      }
    }
  }

  &__card-picture {
    position: relative;
    @media screen and (max-width: 810px) {
      margin-bottom: 0.5rem;
    }
    @media screen and (max-width: 767px) {
      margin-bottom: 1rem;
    }
    img {
      position: relative;
      aspect-ratio: 16 / 10;
      width: 100%;
      height: auto;
      object-fit: cover;
    }
  }

  &__card-content {
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    padding: 0 0 0 4rem;
    @media screen and (max-width: 991px) {
      padding: 0 0 0 2rem;
    }
    @media screen and (max-width: 810px) {
      padding: 1.5rem 0;
      padding: 0.2rem 0;
    }
  }

  &__card-pretitle {
    text-transform: uppercase;
    color: $color-primary;
    font-weight: $font-weight-medium;
    letter-spacing: 1px;
    @media screen and (max-width: 575px) {
      font-size: $header-4;
    }
  }

  &__card-title {
    font-size: $header-2;
    font-weight: $font-weight-bold;
    line-height: 1.4;
    color: $color-dark;
    margin-bottom: $space-1;
    @media screen and (max-width: 1440px) {
      font-size: 2rem;
    }
    @media screen and (max-width: 991px) {
      font-size: $header-2-res;
      margin-bottom: 1rem;
    }
  }

  &__card-descriptions {
    display: flex;
    flex-direction: column;
    gap: $space-02;
  }

  &__card-description {
    display: grid;
    grid-template-columns: auto 1fr;
    gap: $space-1;
    align-items: flex-start;
  }

  &__card-bullet {
    width: 48px;
    height: 48px;
    border-radius: $border-radius;
    background-color: $color-secondary;
    display: flex;
    align-items: center;
    justify-content: center;
    //text-align: center;
    i {
      color: $color-white;
      font-size: 2rem;
    }
  }

  &__card-info {
    display: flex;
    flex-direction: column;
    gap: $space-01;
  }
  &__card-info-title {
    font-size: $font-size-big;
    font-weight: $font-weight-bold;
    line-height: 1;
  }
  &__card-info-description {
    line-height: 1.5;
  }
}
