.feat {
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 6rem;
  @media screen and (max-width: 767px) {
    display: flex;
    flex-direction: column-reverse;

    gap: 2rem;
  }

  &--inverse {
    .feat__content {
      grid-column: 2 / 3;
      grid-row: 1 / 2;
    }
    .feat__picture {
      grid-column: 1 / 2;
      grid-row: 1 / 2;
    }
  }

  &--dark {
    color: $color-background;
    .titulo__pre,
    .titulo__titulo {
      color: $color-background;
    }
  }

  &__content {
  }

  &__picture {
    img {
      width: 100%;
      height: auto;
    }
  }
  &__actions {
    display: flex;
    align-items: center;
    flex-wrap: wrap;
  }
}

.post-body {
  margin-bottom: 1.5rem;
}
